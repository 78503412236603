import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import ServicesImages from "./ServicesImages"

const query = graphql`
  {
    images: allFile(
      filter: { relativeDirectory: { eq: "pages/services/services-fireplace" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ServicesFireplace = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="text-container">
        <p>
          Prior to any installation we usually advice the best possible solution
          for your fireplace. We can supply any type and any style of fireplace,
          including bespoke custom-made ones.
        </p>
        <p>
          If you already have fireplace and would like to convert from solid
          fuel to gas fire, we can fit new gas burner to any custom size
          fireplace or any existing basket. We can also advice you the best
          possible option and design entire fireplace together with a chimney
          system.
        </p>
        <p>
          Thanks to close cooperation with fireplaces manufactures, we're able
          to offer you different design options for you home. From antique
          fireplaces to contemporary ones, from classic open fireplaces to 'hole
          in the wall fireplaces' and bio-ethanol smart fires.
        </p>
        <p>
          All works will be carried out by a fully qualified “Gas Safe” engineer
          or Hetas registered engineer for wood or solid fuel fireplaces. We
          believe that customer service doesn't end once you have your stove
          installed in your home, that's why we always offer additional fire
          maintenance services to all of our customers.
        </p>
        <div className="back-to-button">
          <Link to={"/services"} className="btn btn--small">
            Back to services
          </Link>
        </div>
      </article>
      <aside className="image-container">
        <ServicesImages data={data} />
      </aside>
    </Wrapper>
  )
}

export default ServicesFireplace

const Wrapper = styled.main`
  .image-container {
    margin-bottom: 2rem;
    height: 100%;
  }
  .text-container p {
    line-height: 2;
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;

    .text-container {
      grid-column: 1 / span 6;
    }

    .image-container {
      grid-column: 7 / -1;
    }
  }
`
