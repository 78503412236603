import React from "react"
import SectionTitle from "../../components/SectionTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageWrapper from "../../components/layout/PageWrapper"
import ServicesFireplace from "../../components/content/ServicesFireplace"

const GasFireplaceInstallers = () => (
  <Layout>
    <SEO
      title="Gas Fireplace Installers"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design and Installation"
    />
    <PageWrapper>
      <SectionTitle
        title="Fireplace installation"
        strapline="Gas, solid fuel and bio-ethanol fuel fireplace"
      />
      <ServicesFireplace />
    </PageWrapper>
  </Layout>
)

export default GasFireplaceInstallers
